import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import FluidImage from '../components/FluidImage';
import Layout from '../components/Layout';
import SignUpForm from '../components/SignUpForm';

import firstConvertcalcualtorImg from '../assets/img/convertcalculator-1.png';
import secondConvertcalcualtorImg from '../assets/img/convertcalculator-2.png';

import signatureImg from '../assets/img/signature.png';

const OurStoryPage = (props) => {
  const { data } = props;
  const { triangleImg } = data;

  return (
    <Layout {...props} hasWave>
      <Helmet>
        <title>
          Our Story | ContactBubble - The accessible contact form for your website or app
        </title>
        <meta
          name="description"
          content="This is our background story. ContactBubble is an embeddable contact form that runs on email."
        />

        <meta name="og:title" content="Our Story | ContactBubble" />
        <meta
          name="og:description"
          content="This is our background story. ContactBubble is an embeddable contact form that runs on email."
        />
        <meta name="og:url" content="https://www.contactbubble.com/our-story" />
      </Helmet>

      <div className="container pt-16 pb-8">
        <h1 className="text-center">Our story</h1>
      </div>

      <div className="bg-white py-8">
        <div className="container-md">
          <div>
            <h1>Hi there👋!</h1>
            <p className="text-xl">
              We are the makers behind ConvertCalculator . ConvertCalculator is a
              {' '}
              <a href="https://www.convertcalculator.co?utm_source=ContactBubble" target="_blank">
                powerful calculator form builder
              </a>
              , that enables lead generation and increased conversion. ConvertCalculator provides a
              DIY-proces to build and run your unique form.
            </p>

            <p>
              We launched ConvertCalculator in 2018. Customer interaction proved vital in the early
              stages of building our product. It still does. We have learned so much by continuous
              customer interactions. Learnings about how they found us. Why they were looking for
              us. Or straight up product feedback.
              {' '}
            </p>

            <p>
              To get us started we used a product called Intercom. We loved the fact that users had
              an easy, low barrier way to approach us. Unfortunately that great feeling did not
              last.
              {' '}
            </p>

            <p>
              For one, products like Intercom are very expensive. And offer a lot of features we do
              not need.
              {' '}
            </p>

            <p>
              But more important, a small team proved unsuited to run customer service through chat.
              We are based in Europe. But we serve customers all over the world.
              {' '}
            </p>

            <p>
              Chat had the expectation real-time response. And the questions were often short and
              unclear. So we were not able to serve our users how we wanted.
            </p>
          </div>
        </div>
      </div>

      <div className="py-8 flex container">
        <div>
          <img src={firstConvertcalcualtorImg} alt="" />
        </div>
        <div>
          <img src={secondConvertcalcualtorImg} alt="" />
        </div>
      </div>

      <div className="py-12 bg-white">
        <div className="container-md">
          <p className="text-xl">
            As a solution, we build ContactBubble. ContactBubble offers a similar approach for
            engaging our customer interactions, but handles the interaction in an optimised
            environment. The e-mail client.
          </p>
          <p>
            This solution works perfect for us. We love the efficiency our e-mail client, in our
            case Gmail, provides. And when written communication does not do the job, we just
            schedule a call.
          </p>
          <p>And we want to share this experience with you.</p>
          <p>
            <blockquote>
              The startup and maker community is quite opinionated. As are we. So if you hate
              e-mail, we are not for you.
            </blockquote>
          </p>
          <p>
            We're very curious to learn how you like to handle your customer interactions. So please
            reach out. You know where to find us!
          </p>
          <p className="mb-4">All the best,</p>
          <img src={signatureImg} alt="" className="md:w-1/2" />
        </div>
      </div>

      <div className="py-12 bg-white">
        <div className="container-md">
          <div className="callout border-dashed border-grey-darkest border-2 p-8">
            <h3>Get your ContactBubble now!</h3>
            <SignUpForm />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OurStoryPage;

export const query = graphql`
  query OurStoryImageQuery {
    triangleImg: file(relativePath: { eq: "triangle.png" }) {
      ...FluidLargeImage
    }
  }
`;
